import "./App.css";
import "antd/dist/antd.css";
import {
  Form,
  Input,
  Select,
  Typography,
  InputNumber,
  Radio,
  Checkbox,
  Col,
  Row,
  Button,
  message,
} from "antd";
import { Component } from "react";
import logo from "./img/taurena_efekts.svg";

const { Title, Text } = Typography;
const { Option } = Select;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      familyType: "",
      suveryFinished: false,
      privacyChecked: false,
      privacyError: false
    };
  }

  renderFormFields = () => {
    if (this.state.familyType === "multiple_children") {
      return (
        <Form.Item
          label="Lūdzam norādīt, kurš pēc kārtas bērns ir ģimenē:"
          name="number_of_children"
          rules={[
            {
              type: "number",
              min: 1,
              message: "Ievadiet skaitli no 1 ieskaitot!",
            },
            { required: true, message: "Ievadiet skaitli no 1 ieskaitot!" },
          ]}
        >
          <InputNumber />
        </Form.Item>
      );
    } else if (this.state.familyType === "not_full") {
      return (
        <Form.Item
          name="only_parent"
          label="Lūdzam norādīt, kurš audzina bērnu:"
          rules={[{ required: true, message: "Izvēlieties kādu no opcijām!" }]}
        >
          <Radio.Group>
            <Radio value="father">Tēvs</Radio>
            <Radio value="mother">Māte</Radio>
          </Radio.Group>
        </Form.Item>
      );
    } else if (this.state.familyType === "adopted") {
      return (
        <Form.Item
          name="another_child"
          label="Lūdzam norādīt, vai ģimenē aug vēl kāds:"
          rules={[{ required: true, message: "Izvēlieties kādu no opcijām!" }]}
        >
          <Radio.Group>
            <Radio value="another_adopted_child">Adoptēts bērns</Radio>
            <Radio value="another_biological_child">Bioloģiskais bērns</Radio>
            <Radio value="only_child">Ģimenē šis ir vienīgais bērns</Radio>
          </Radio.Group>
        </Form.Item>
      );
    } else if (this.state.familyType === "guardian") {
      return (
        <Form.Item
          name="guardian_child"
          label="Lūdzam norādīt, vai ģimenē aug:"
          rules={[{ required: true, message: "Izvēlieties kādu no opcijām!" }]}
        >
          <Radio.Group>
            <Radio value="another_guardian_child">
              Aizbildnībā esošs bērns
            </Radio>
            <Radio value="another_biological_child">Bioloģiskais bērns</Radio>
            <Radio value="only_child">Ģimenē šis ir vienīgais bērns</Radio>
          </Radio.Group>
        </Form.Item>
      );
    } else if (this.state.familyType === "homosexual") {
      return (
        <Form.Item
          name="homosexual_family_type"
          label="Lūdzam norādīt, kāda veida ģimene:"
          rules={[{ required: true, message: "Izvēlieties kādu no opcijām!" }]}
        >
          <Radio.Group>
            <Radio value="male_homosexual">Vīriešu</Radio>
            <Radio value="female_homosexual">Sieviešu</Radio>
          </Radio.Group>
        </Form.Item>
      );
    } else {
      return null;
    }
  };

  completeSurvey = (values) => {
    var remapValues = values;
    // Object.keys(values).map(function (key, index) {
    //   remapValues[key] = [{ value: remapValues[key] }];
    // });

    if (!this.state.privacyChecked) {
      this.setState({ privacyError: true });
      return;
    }

    const formMessage = {
      webform_id: "taurena_efekts",
      ...remapValues,
    };

    fetch(`/webform_rest/submit?_format=json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formMessage),
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ suveryFinished: true });
      } else {
        message.error("Notikusi kļūda, lūdzu mēģiniet vēlreiz!");
      }
    });
  };

  render() {
    if (!this.state.suveryFinished) {
      return (
        <>
          <div className="logo-div">
            <img src={logo} alt="Taurena efekta logo" />
          </div>
          <div className="form-outer-div">
            <Form
              layout="vertical"
              className="survey-form"
              onFinish={this.completeSurvey}
            >
              <Title level={5}>INFORMĀCIJA PAR BĒRNU</Title>
              <Form.Item
                label="Bērna vārds"
                name="child_name"
                rules={[{ required: true, message: "Ievadiet bērna vārdu!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Bērna uzvārds"
                name="child_surname"
                rules={[{ required: true, message: "Ievadiet bērna uzvārdu!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Skola"
                name="school"
                rules={[{ required: true, message: "Ievadiet skolu!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Klase"
                name="class"
                rules={[{ required: true, message: "Ievadiet klasi!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Novads"
                name="region"
                rules={[{ required: true, message: "Ievadiet novadu!" }]}
              >
                <Input />
              </Form.Item>

              <Title level={5} className="new-section-title">
                INFORMĀCIJA PAR ĢIMENI*
              </Title>
              <Form.Item
                label="Kāda veida ģimenē bērns aug?"
                name="family_type"
                rules={[
                  { required: true, message: "Izvēlieties ģimenes veidu!" },
                ]}
              >
                <Select
                  placeholder="Izvēlieties ģimenes veidu"
                  onChange={(value) => this.setState({ familyType: value })}
                >
                  <Option value="multiple_children">Daudzbērnu ģimene</Option>
                  <Option value="not_full">Nepilna ģimene</Option>
                  <Option value="adopted">Adoptēts bērns</Option>
                  <Option value="guardian">Aizbildniecībā paņemts bērns</Option>
                  <Option value="homosexual">
                    Bērns no homoseksuālas ģimenes
                  </Option>
                </Select>
              </Form.Item>
              {this.renderFormFields()}
              <Form.Item
                label="Vecāka vai aizbildņa vārds un uzvārds"
                name="parent_name_surname"
                rules={[
                  { required: true, message: "Ievadiet vārdu un uzvārdu!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Vecāka vai aizbildņa telefona numurs"
                name="parent_phone_number"
                rules={[
                  { required: true, message: "Ievadiet telefona numuru!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Vecāka vai aizbildņa e-pasts"
                name="parent_email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Ievadiet e-pasta adresi!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="parent_education"
                label="Vecāka vai aizbildņa izglītība"
                rules={[
                  { required: true, message: "Izvēlieties kādu no opcijām!" },
                ]}
              >
                <Radio.Group>
                  <Radio value="base_education">Pamata</Radio>
                  <Radio value="high_school_education">Vidējā</Radio>
                  <Radio value="high_school_special_education">
                    Vidējā speciālā
                  </Radio>
                  <Radio value="college_education">
                    Pirmā līmeņa augstākā (koledža)
                  </Radio>
                  <Radio value="highest_education">Augstākā</Radio>
                </Radio.Group>
              </Form.Item>
              <Text strong>
                *Lūdzam bērnu uz pirmo vizīti ar ārstu psihoterapeitu ierasties
                kopā ar vecākiem vai ģimeni. Tas ļaus bērnam iegūt palīdzību
                iespējami lielākajā mērā.
              </Text>
              <Title level={5} className="new-section-title">
                PROBLĒMAS RAKSTUROJUMS
              </Title>
              <Form.Item
                name="child_problem_characteristics"
                label="Iemesls, kāpēc bērnam nepieciešama psihoterapeita palīdzība (atzīmēt visus atbilstošos):"
                rules={[
                  {
                    required: true,
                    message: "Lūdzu atzīmējiet vismaz 1 iemeslu!",
                  },
                ]}
              >
                <Checkbox.Group>
                  <Row>
                    <Col span={24}>
                      <Checkbox
                        value="bad_relations"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Neveidojas labvēlīgas attiecības ar vienaudžiem;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="fights_with_others"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Regulāri konflikti ar klases biedriem un vienaudžiem;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="behavioural_problems"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Uzvedības problēmas ar pieaugušajiem (vecākiem,
                        skolotājiem, u.c.);
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="aggressive"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Agresīva uzvedība (vēlme kauties, lauzt un bojāt
                        priekšmetus u.c.);
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="self_harm"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Tieksme darīt sev pāri;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="suicidal"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Pašnāvnieciskas tieksmes;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="wandering_off"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Klaiņošana;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="lying"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Melošana, patiesības slēpšana;
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="stealing"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Zagšana;
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="Lūdzu, īsi aprakstiet problēmas cēloņus, izpausmes un kad tās sākās (līdz 200 vārdiem):"
                rules={[
                  {
                    required: true,
                    message: "Lūdzu aprakstiet problēmas cēļoņus!",
                  },
                ]}
              >
                <Input.TextArea allowClear showCount />
              </Form.Item>
              <div style={{ margin: "20px 0px" }}>
                <Checkbox
                  onChange={(e) =>
                    this.setState({
                      privacyChecked: e.target.checked,
                      privacyError: false,
                    })
                  }
                >
                  Apstiprinu, ka piekrītu norādīto personas datu apstrādei
                  saskaņā ar kampaņas "Vārdi sāpina! Klusums nogalina..."
                  <a href={process.env.PUBLIC_URL + `/privatuma_politika.pdf`}> privātuma noteikumiem</a>.
                </Checkbox>
              </div>
              <div
                style={{
                  margin: "20px 0px",
                  color: "#FF4D4F",
                  display: !this.state.privacyError ? "none" : "initial",
                }}
              >
                <p>Apstipriniet, ka piekrītat datu apstrādei!</p>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
              >
                Iesniegt
              </Button>
            </Form>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="logo-div">
            <img src={logo} alt="Taurena efekta logo" />
          </div>
          <div className="form-outer-div">
            <Title level={3}>Pieteikums saņemts!</Title>
          </div>
        </>
      );
    }
  }
}

export default App;
